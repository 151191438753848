import React from 'react';
import styled from 'styled-components';
import { View } from 'react-native';
import { Text, Asset, IconSvg, Tooltip, useTheme, useResponsive, s } from '@uikit';
import Info from '@uikit/icons/Info';
import { useDebug } from '@app/hooks/useDebug';
import { CatchTheme } from '@uikit/themes/catch';
import { useLayoutContext } from './LayoutContext';

export const Layout = ({
  children,
  style,
  topSpace,
  bottomSpace,
  sheetSpace, //specifically to space bottom sheets
  color,
  fullHeight,
  fullWidth,
  margins,
  detailMargins,
  center,
  centerAll,
  colorOnBounce,
  dark,
  spacer,
  flex,
  debuggable,
  mobileSnap,
  flexRow,
  onHeight,
  ...rest
}) => {
  const { theme, themeColors } = useTheme();
  const { showLayout } = useDebug();
  const { res } = useResponsive(spacing);
  return (
    // @ts-ignore
    <View
      onLayout={(event) => {
        if (onHeight) {
          onHeight(event.nativeEvent.layout.height); // @todo document why this is needed
        }
      }}
      style={[
        sheetSpace && theme.sheetLayout,
        styles.layoutContainer,
        topSpace && theme.topSpace4,
        bottomSpace && theme.bottomSpace4,
        !!color && { backgroundColor: themeColors[`${color}Color`] },
        fullWidth && styles.fullWidth,
        fullHeight && styles.fullHeightScroll,
        margins && res.margins,
        mobileSnap && res.mobileSnap,
        (center || centerAll) && styles.alignCenter,
        !!colorOnBounce && styles.colorOnBounce,
        !!dark && theme.darkBg,
        !!showLayout && debuggable && theme._overlay,
        flex === 1 && theme.flex1,
        !!flexRow && theme.flexRow,
        { overflow: 'visible' },
        style,
      ]}
      {...rest}
    >
      {children}
    </View>
  );
};

export const StyledScroll = styled.div<{
  margins?: boolean;
  bottomSpace?: boolean;
  toolbarHeight: number;
}>`
  flex: 1;
  height: 100%;
  overflow: scroll;
  padding-top: 64px;
  margin-top: -64px;

  ${({ bottomSpace }) =>
    bottomSpace &&
    `
    padding-bottom: 64px;
  `}

  ${({ margins }) =>
    margins &&
    `
    margin-left: ${CatchTheme.spacing.mobile.margins}px;
    margin-right: ${CatchTheme.spacing.mobile.margins}px;
    
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.s}px) {
      margin-left: ${CatchTheme.spacing.desktop.margins}px;
      margin-right: ${CatchTheme.spacing.desktop.margins}px;  
    }
  `}

  margin-bottom: ${(p) => p.toolbarHeight}px;
`;

export const Scroll = (props) => {
  const { toolbarHeight } = useLayoutContext();
  return <StyledScroll {...props} toolbarHeight={toolbarHeight} />;
};

export const Header = ({
  asset, // only base64, icon, or uri matters
  pretitle,
  title,
  titleSize = 'block', // page | form | block | smol
  subtitleSize,
  subtitleMaxSize,
  tooltip,
  titleColor,
  subtitle,
  subtitles = [],
  subtitleColor,
  textColor, // fallback color for both title + subtitles (deprecate at some point?)
  align = 'left', // left OR center
  spaced, // adds small space between title and subtitles (often for big centered things)
  icon /** @deprecated */,
  loading,
  bottomSpace,
  rightSpace,
  sheet = false,
  light,
  titleLines,
  fluid,
  staticTitle,
  graphic,
  svg,
  style,
  weight,
}: any) => {
  const { theme } = useTheme();
  const { showLayout } = useDebug();
  const allSubtitles = subtitle ? [subtitle] : subtitles;

  const textSize = {
    xl: {
      pretitle: 'h4',
      title: 'xl',
      subtitle: 'h4',
    },
    display: {
      pretitle: 'p',
      title: 56,
      subtitle: 'p',
    },
    page: {
      pretitle: 'p',
      title: 'h1',
      subtitle: 'p',
    },
    form: {
      pretitle: 'p',
      title: 'h2',
      subtitle: 'p',
    },
    block: {
      pretitle: 'fp',
      title: 'h3',
      subtitle: 'fp',
    },
    smol: {
      pretitle: 'fp',
      title: 'btn',
      subtitle: 'fp',
    },
  };

  return (
    <View>
      <View
        style={[
          sheet && theme.sheetHeader,
          showLayout && theme._overlay,
          bottomSpace && theme.bottomSpace4,
          bottomSpace && titleSize === 'smol' && theme.bottomSpace2,
          theme.fullWidth,
          align === 'center' && theme.centerSelf,
          align === 'center' && theme.alignCenter,
          style,
        ]}
      >
        {!!(asset || icon) && (
          <Asset
            {...asset}
            icon={asset?.icon || icon}
            size={asset?.size || 'xl'}
            containerStyle={theme.bottomGutter2}
          />
        )}
        {!!svg && <IconSvg svgComponent={svg} size="md" style={theme.bottomGutter1} {...asset} />}
        {!!graphic && <div style={{ marginBottom: 16 }}>{graphic}</div>}

        {pretitle && (
          <Text color="subtle" size={textSize[titleSize]?.pretitle}>
            {pretitle}
          </Text>
        )}
        {!!title && (
          <Text
            numberOfLines={titleLines}
            size={textSize[titleSize]?.title}
            group
            align={align}
            color={titleColor || textColor}
            loading={loading && !!title && !staticTitle}
            style={[spaced && theme.bottomGutter1, rightSpace && theme.rightGutter10]}
            weight={weight || (!!light ? 'medium' : 'semibold')}
            fluid={fluid}
          >
            {title}
          </Text>
        )}
        {!!tooltip && (
          <View style={theme.alignRight}>
            <Tooltip label={tooltip} type="sheet">
              <Info />
            </Tooltip>
          </View>
        )}
        {allSubtitles.map((subtitle, i) => (
          <Text
            key={`s-${i}`}
            loading={loading}
            size={subtitleSize || textSize[titleSize]?.subtitle}
            group
            align={align}
            color={subtitleColor || textColor || 'secondary'}
            fluid={fluid}
            maxSize={subtitleMaxSize}
          >
            {subtitle}
          </Text>
        ))}
      </View>
    </View>
  );
};

const spacing = {
  margins: {
    desktop: {
      paddingHorizontal: CatchTheme.spacing.desktop.margins,
    },
    mobile: {
      paddingHorizontal: CatchTheme.spacing.mobile.margins,
    },
    default: {
      paddingHorizontal: CatchTheme.spacing.mobile.margins,
    },
  },
  mobileSnap: {
    desktop: {
      marginHorizontal: -CatchTheme.spacing.desktop.margins,
      width: 'auto',
    },
    mobile: {
      marginHorizontal: -CatchTheme.spacing.mobile.margins,
      width: 'auto',
    },
    default: {
      marginHorizontal: -CatchTheme.spacing.mobile.margins,
      width: 'auto',
    },
  },
};

const styles = {
  layoutContainer: {
    width: '100%',
    alignItems: 'stretch',
  },

  scrollContainer: {
    width: '100%',
  },
  /** remove clipping of shadows */
  scrollContainerHorizontal: {
    paddingVertical: 24,
  },
  alignCenter: {
    alignItems: 'center',
  },
  rowContainer: {
    flexDirection: 'row',
  },
  bounds: s?.page,
  topSpace: {
    paddingTop: 48,
  },
  bottomSpace: {
    paddingBottom: 48,
  },
  flex1: s?.flex1,
  fullHeightScroll: {
    minHeight: '100%',
  },
  fullWidthScroll: {
    minWidth: '100%',
  },
};

Scroll.displayName = 'Scroll';
Layout.Header = Header;
Layout.Scroll = Scroll;

export default Layout;
