import { useMemo } from 'react';
import {
  useQuery,
  GET_BANKING_SETUP_DETAILS,
  useMutation,
  SIGN_AGREEMENTS,
  AgreementType,
  useFintechQuery,
  GET_GOALS,
  useFintechMutation,
  GoalStatus,
  ACTIVATE_GOALS,
} from '@data';
import { FieldsConfig, GoalSlug } from '@types';
import { ComplexRow, Button, Link } from '@uikit';
import { useCopy } from '@util';
import { BasicLayout, Toolbar, Stack, Section } from '@layouts';
import { useForm, Fields } from '@app/forms';
import { ConfirmationBlueprint } from '@blueprints/ConfirmationBlueprint';
import { useAgreements } from '@app/hooks';
import { LegalAgreement } from '../agreements';
import { sheets, useSheet } from '@app/navigate';

interface FintechConfirmPageProps {
  slug: GoalSlug;
  handleNext: (updates?: object) => void;
  doneMessage?: string;
}

export const FintechConfirmPage = ({ slug, handleNext, doneMessage }: FintechConfirmPageProps) => {
  const { c } = useCopy('catch');
  const { close: closeSheet, open: openSheet } = useSheet();

  // fetches initial data
  const setup = useQuery(GET_BANKING_SETUP_DETAILS);
  const fintech = useFintechQuery(GET_GOALS);

  const { agreements } = useAgreements([
    AgreementType.UnitElectronicDisclosuresConsent,
    AgreementType.UnitDepositTermsAndConditions,
  ]);

  const loading = setup.loading || fintech.loading;

  const [activateGoals, { loading: creating, called, error }] = useFintechMutation(ACTIVATE_GOALS);

  const [signAgreements] = useMutation(SIGN_AGREEMENTS);

  const fields: FieldsConfig = [
    {
      type: 'checkbox',
      name: 'unitAccountDisclosures',
      required: true,
      display: !setup?.data?.me?.user?.agreements?.unitAccountDisclosures,
      grouped: true,
      label: c('module.disclosures.unit', {
        electronic: <LegalAgreement type={AgreementType.UnitElectronicDisclosuresConsent} />,
        terms: <LegalAgreement type={AgreementType.UnitDepositTermsAndConditions} />,
      }),
    },
  ];

  const shouldDisplayAgreements = fields.some((field) => field.display);

  /**
   * We have two variations of this page that should be handled:
   * 1) We are on an individual goal setup (via slug) and should only show that goal
   * 2) We are in bulk setup and should show all DRAFT goals
   */
  const visibleGoals = useMemo(() => {
    const goals = fintech?.data?.goals || [];

    if (slug) {
      const currentGoal = goals?.find((goal) => goal.slug === slug);
      return currentGoal ? [currentGoal] : [];
    }

    // otherwise, return all draft goals
    return goals
      ?.filter((goal) => goal.status === GoalStatus.Draft)
      ?.sort((a, b) => (a.withholdingPercentage > b.withholdingPercentage ? -1 : 1));
  }, [fintech]);

  const form = useForm<{ unitAccountDisclosures: boolean }>({
    loading,
    disabled: creating,
    initialValues: {},
    fields,
    onSubmit: async (values) => {
      if (values.unitAccountDisclosures) {
        const input = agreements.map((agreement) => ({
          agreementType: agreement.agreementType as AgreementType,
          version: agreement.version as string,
        }));

        await signAgreements({ variables: { input } });
      }

      activateGoals({
        variables: {
          input: {
            goalIds: visibleGoals?.map((g) => g.id || ''),
          },
        },
      });
    },
  });

  return (
    <ConfirmationBlueprint
      loading={creating}
      titles={
        !slug
          ? { done: doneMessage || 'You’ve successfully created your Catch Banking Account!' }
          : undefined
      }
      called={called}
      error={!!error}
      onSuccess={handleNext}
    >
      <BasicLayout
        loading={loading}
        variant="confirm"
        title="Confirm your withholding"
        toolbar={
          <Toolbar type="stack">
            <Button
              testID="confirm"
              loading={loading}
              inherit
              disabled={form.disableSubmit}
              onPress={form.submitForm}
            >
              Confirm
            </Button>
          </Toolbar>
        }
      >
        <Stack spacing="2">
          {visibleGoals.length > 0 && (
            <Section title="Your goals" subtitle="You can adjust this at any time.">
              <Stack separatorComponent border>
                {visibleGoals.map((goal) => (
                  <ComplexRow
                    testID={goal.id || ''}
                    inset
                    key={goal.id}
                    label={goal.name}
                    accessory={
                      <Link
                        testID="edit-percentage"
                        onPress={() =>
                          openSheet(sheets.WITHHOLDING_EDIT, {
                            closeSheet,
                            goal,
                          })
                        }
                      >
                        {goal.withholdingPercentage}%
                      </Link>
                    }
                    asset={{ render: goal.type, size: 'sm' }}
                  />
                ))}
              </Stack>
            </Section>
          )}

          {shouldDisplayAgreements && (
            <Section title="Legal">
              <Stack spacing="3">
                <Fields form={form} fields={fields} stackProps={{ spacing: '+gap' }} />
              </Stack>
            </Section>
          )}
        </Stack>
      </BasicLayout>
    </ConfirmationBlueprint>
  );
};
