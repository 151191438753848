import type { Theme } from '../types';

export const CatchTheme: Theme = {
  name: 'Catch',
  settings: {
    focusLock: true,
  },
  colors: {
    light: {
      fg: '#100F13',
      fg2: '#110E148C',
      fg3: '#00000088',
      bg: '#FFFFFF',
      bgTransparent: 'transparent',
      bgSheet: '#FFFFFF',
      bgCard: '#FFFFFF',
      border: '#100F1311',
      bg2: '#100F1308',
      bg3: '#100F1308',
      fade: '#FFFFFFF2',
      bgGray: '#F5F3F6',
      void: '#EEEEEE',
      veil: '#00000088',
      brand: '#9B1DFF',
      brandLight: '#EBD2FF',
      coverage: '#9B1DFF',
      coverageLight: '#EBD2FF',
      income: '#9B1DFF',
      incomeLight: '#EBD2FF',
      retirement: '#00BDDB',
      retirementLight: '#D1F3FA',
      saved: '#A4EA43',
      savedLight: '#DCFFC0',
      taxes: '#FFAD05',
      taxesLight: '#FFE8C9',
      positive: '#007F5F',
      positiveLight: '#008050',
      medium: '#FFAD05',
      mediumLight: '#FFE8C9',
      negative: '#EE0000',
      negativeLight: '#EE000033',
      snow: '#FFFFFF',
      aetna: '#7D3F98',
      aetnaLight: '#7D3F98',
    },
    dark: {
      fg: '#FFFFFF',
      fg2: '#FFFFFFCC',
      fg3: '#FFFFFF73',
      bg: '#111012',
      bgTransparent: 'transparent',
      bgSheet: '#262527',
      bgCard: '#FFFFFF19',
      bg2: '#FFFFFF19',
      bg3: '#FFFFFF08',
      fade: '#00000010',
      border: '#FFFFFF19',
      bgGray: '#333234',
      void: '#EEEEEE',
      veil: '#00000088',
      brand: '#A540F7',
      brandLight: '#2E0E49',
      coverage: '#A540F7',
      coverageLight: '#2E0E49',
      income: '#A540F7',
      incomeLight: '#2E0E49',
      retirement: '#00BDDB',
      retirementLight: '#00434D',
      saved: '#A4EA43',
      savedLight: '#2C401B',
      taxes: '#FF8800',
      taxesLight: '#482C0E',
      positive: '#00C78B',
      positiveLight: '#01C18733',
      medium: '#FF8800',
      mediumLight: '#FF880033',
      negative: '#CB484A',
      negativeLight: '#CB484A33',
      snow: '#FFFFFF',
      aetna: '#7D3F98',
      aetnaLight: '#7D3F98',
    },
  },
  gradients: {
    light: {
      brand: ['#9D11D9', '#7F09DB'],
      brandLight: ['#F7D7FF', '#FADDF4', '#FFE8E0'],
      brandSmall: ['#9C11D9', '#8516DB'],
      coverage: ['#EE4B7B', '#D83177'],
      coverageLight: ['#F7D7FF', '#FADDF4', '#FFE8E0'],
      coverageSmall: ['#9C11D9', '#8516DB'],
      income: ['#9D11D9', '#7F09DB'],
      incomeLight: ['#F9E2FF', '#FFF9FC', '#FFE8E0'],
      incomeSmall: ['#9C11D9', '#8516DB'],
      lobby: ['#F7E0FC', '#E0E3FF'],
      retirement: ['#17CFDC', '#17B9D7'],
      retirementLight: ['#DEFFF9', '#EBFEFF', '#E1F8FF'],
      retirementSmall: ['#17CFDC', '#17B9D7'],
      saved: ['#D7FA2C', '#7ACB17'],
      savedLight: ['#F7FFD4', '#F8FFF2', '#E5FFDC'],
      savedSmall: ['#99DA0B', '#76CB10'],
      taxes: ['#FFAD05', '#F36602'],
      taxesLight: ['#FFE8E1', '#FFF9F3', '#FFF3CA'],
      taxesSmall: ['#FA8E04', '#F36803'],
      positive: ['#01C187', '#01C187'],
      positiveLight: ['#01C18733', '#01C18744'],
      medium: ['#FFAD05', '#FFAD05'],
      mediumLight: ['#FFAD0533', '#FFAD0533'],
      negative: ['#EE0000', '#EE0000'],
      negativeLight: ['#EE000033', '#EE000044'],
      aetna: ['#7D3F98', '#7D3F98'],
      aetnaLight: ['#7D3F98', '#7D3F98'],
    },
    dark: {
      lobby: ['#2B1433', '#141836'],
      brand: ['#9D11D9', '#7F09DB'],
      brandSmall: ['#9C11D9', '#8516DB'],
      brandLight: ['#E16BFF26', '#F07EB71A', '#FF916B26'],
      coverage: ['#9D11D9', '#7F09DB'],
      coverageSmall: ['#9C11D9', '#8516DB'],
      coverageLight: ['#E16BFF26', '#F07EB71A', '#FF916B26'],
      income: ['#9D11D9', '#7F09DB'],
      incomeLight: ['#E16BFF26', '#F07EB71A', '#FF916B26'],
      incomeSmall: ['#9C11D9', '#8516DB'],
      retirement: ['#17CFDC', '#17B9D7'],
      retirementLight: ['#4BF3F61A', '#4BF3F61A', '#68DBFF26'],
      retirementSmall: ['#50ABB6', '#45959E'],
      saved: ['#D7FA2C', '#7ACB17'],
      savedLight: ['#E5FF6826', '#BBFF681A', '#8FFF6826'],
      savedSmall: ['#99DA0B', '#76CB10'],
      taxes: ['#FFAD05', '#F36602'],
      taxesLight: ['#FF7E5226', '#FFAB521A', '#FFD95226'],
      taxesSmall: ['#FA8E04', '#F36803'],
      positive: ['#00C78B', '#00C78B'],
      positiveLight: ['#00C78B33', '#00C78B44'],
      medium: ['#FFAD05', '#FFAD05'],
      mediumLight: ['#FFAD0533', '#FFAD0533'],
      negative: ['#CB0F0F', '#CB0F0F'],
      negativeLight: ['#CB0F0F33', '#CB0F0F44'],
      aetna: ['#7D3F98', '#7D3F98'],
      aetnaLight: ['#7D3F98', '#7D3F98'],
    },
  },
  corners: {
    micro: 2,
    sm: 12,
    md: 16,
    lg: 24,
    round: 32,
  },
  spacing: {
    mobile: {
      margins: 24,
      stack: 24,
      hStack: 16,
      paddingX: 4,
      paddingY: 4,
    },
    desktop: {
      margins: 24,
      stack: 24,
      hStack: 16,
      paddingX: 4,
      paddingY: 4,
    },
  },
  assets: {
    mini: 12,
    glyph: 16,
    xs: 24,
    sm: 32,
    md: 40,
    lg: 56,
    xl: 64,
    xxl: 72,
    xxxl: 88,
    plus: 96,
    jumbo: 96,
    humongous: 150,
  },
  containers: {
    dialog: 360,
    formSheet: 480,
    pageSheet: 640,
    largeSheet: 640,
    miniPage: 640,
    basic: 800,
    page: 1080,
  },
  animation: {
    curve: 'cubic-bezier(0.23, 1, 0.32, 1)',
    bounce: 'cubic-bezier(.13,1.34,.24,.97)',
    delay: 300,
    delayMedium: 1500,
    delayLong: 3000, // for closing sheets and stuff like that
    durationShort: 250,
    duration: 400,
    durationLong: 650,

    springConfig: {
      tension: 300,
      mass: 1,
      friction: 29,
      velocity: 0.01,
      precision: 0.01,
    },
  },
  constants: {
    blur: 24,
    buttonHeight: 46,
    borderWidth: 1.5,
    checkRadioSize: 18,
    modalBlur: 16,
    disabledOpacity: 0.35,
    promptWidthSm: 280,
    promptWidthLg: 320,
    rootHeaderHeight: 320,
  },
  font: {
    face: {
      Title: 'InterDisplay',
      Mid: 'InterDisplay',
      Body: 'Inter',
      Caption: 'Inter',
      Hand: 'Caveat',
      Mono: 'Jetbrains Mono',
    },
    size: {
      XL: 48,
      PageTitle: 32,
      FormTitle: 24,
      BlockTitle: 20,
      Heading: 20,
      Button: 16,
      Body: 16,
      Small: 15,
      Caption: 13,
      SmallCaps: 11,
    },
    ratio: 1.3,
  },
};

export const content = { ...CatchTheme.constants };
export const theme = CatchTheme;
