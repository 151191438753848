import React, { useMemo, useState } from 'react';
import { Button, Instructions, Link, Loading, stateIcons } from '@uikit';
import { HiccupBlueprint } from '@blueprints';
import {
  useQuery,
  queries,
  StateReferenceQueryData,
  StateReferenceQueryVars,
  useMutation,
  UpsertConversionStatus,
  ConversionStatusType,
  ConversionStatusEnum,
  HealthExplorerQuery,
} from '@data';
import { STATES, currentYear, useCopy } from '@app/utils';
import { links, ScreenDefinition } from '@navigate';
import { FontSize, Route } from '@types';
import { Stack } from '@app/layouts';
import { STATE_INSTRUCTIONS } from '../config/stateInstructions';

const ExplorerStateHandoff = ({ userID }) => {
  const [showInstructions, setShowInstructions] = useState(false);
  const { c } = useCopy('catch');

  const { data } = useQuery(HealthExplorerQuery);

  const [upsertConversion] = useMutation(UpsertConversionStatus);

  const { state, year } = useMemo(() => {
    return {
      state: data?.viewerTwo?.healthExplorerData?.state || '',
      year: data?.viewerTwo?.healthExplorerData?.coverageYear || currentYear,
    };
    // return data?.viewerTwo?.healthExplorerData?.state || '';
  }, [data?.viewerTwo?.healthExplorerData]);

  const handleStateDIY = () => {
    const input = {
      year,
      type: ConversionStatusType.Health,
      status: ConversionStatusEnum.StateDiy,
    };

    upsertConversion({ variables: { input } });

    setShowInstructions(true);
  };

  const { loading, data: stateData } = useQuery<StateReferenceQueryData, StateReferenceQueryVars>(
    queries.STATE_REFERENCE,
    {
      variables: { state },
      skip: !state,
    },
  );

  const { url, name } = stateData?.reference?.stateHealthReference || {};

  const healthPlan = useMemo(() => {
    return data?.viewerTwo?.healthExplorerData?.healthPlanDetails;
  }, [data?.viewerTwo?.healthExplorerData]);

  const { concierge, steps, exchangeLink, planId, planName } = useMemo(() => {
    const config = STATE_INSTRUCTIONS[state] || {};

    return {
      concierge: config.concierge || false,
      steps: config.steps || [],
      exchangeLink: <Link href={url}>{name}</Link>,
      planId: healthPlan?.id || 'Not selected',
      planName: healthPlan?.name || 'Not selected',
    };
  }, [state, healthPlan, url, name]);

  // adding this here because otherwise its jumpy
  if (loading || !state) {
    return <Loading />;
  }

  const instructions = (
    <Instructions
      state={state}
      steps={steps.map((step) => ({
        id: step.title,
        title: c(step.title, { exchangeLink }),
        subtitle: c(step.subtitle, { planId, planName }),
      }))}
    />
  );

  return (
    <HiccupBlueprint
      loading={!state || loading}
      align="center"
      asset={{ icon: stateIcons[state], color: 'text' }}
      title={concierge ? 'How would you like to apply?' : `Next, apply on ${name}`}
      subtitles={[
        concierge
          ? 'Choose whether you’d like step-by-step assistance from a Benefits Advisor or instructions to apply yourself.'
          : `Follow the instructions below. ${STATES[state]} operates its own exchange, so you’ll need to apply directly on your state’s website.`,
      ]}
    >
      {concierge ? (
        <Stack spacing="3" separatorComponent>
          <Button testID="contact" onPress={() => window.open(links.CONTACT, '_blank')}>
            Contact us for assistance
          </Button>
          {showInstructions ? (
            instructions
          ) : (
            <Link testID="instructions" size={FontSize.fp} onPress={handleStateDIY}>
              I&apos;d like instructions to apply on my own
            </Link>
          )}
        </Stack>
      ) : (
        instructions
      )}
    </HiccupBlueprint>
  );
};

export const ExplorerStateHandoffView: ScreenDefinition = {
  name: Route.EXPLORER_STATE_HANDOFF,
  component: ExplorerStateHandoff,
  options: {},
};
