import { gql } from '../generated';

gql(`
  fragment Agreements on Agreements {
    id
    brokerConsent
    termsAndConditions
    unitAccountDisclosures
  }
`);

gql(`
  fragment LegalAgreement on Agreement {
    id
    title
    slug
    version
    agreementType
    body
  }
`);

// this is a content query - should be invoked with useContentQuery
export const GET_LEGAL_AGREEMENTS = gql(`
  query GetLegalAgreements($where: AgreementWhereInput) {
    agreements(where: $where) {
      ...LegalAgreement
    }
  }
`);

export const SIGN_AGREEMENTS = gql(`
  mutation SignAgreement($input: [SignAgreementInput!]!) {
    signAgreements(input: $input) {
      ...Agreements
    }
  }
`);
