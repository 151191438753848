import React, { useMemo } from 'react';
import { useQuery, useMutation, HealthApplicationQuery, UpsertHealthApplication } from '@data';
import { SplitFormBlueprint } from '@blueprints';
import { useCopy } from '@util';
import { Route } from '@types';
import { fields } from './voterRegistrationFields';
import { getInitialValues, formatPayload } from './voterRegistrationUtils';

const VoterRegistration = ({ applicationID, handleNext }) => {
  const { c } = useCopy('catch.ede');

  const { data } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const initialValues = useMemo(() => getInitialValues(data), [data]);

  const [upsert, { loading: submitting }] = useMutation(UpsertHealthApplication, {
    onCompleted: () => handleNext(),
  });

  const onSubmit = (values) => {
    const input = formatPayload(applicationID, values);
    upsert({ variables: { input } });
  };

  return (
    <SplitFormBlueprint
      title={c('VoterRegistration.title')}
      loading={!data}
      submitting={submitting}
      formConfig={{
        initialValues,
        fields,
        onSubmit,
      }}
    />
  );
};

export const VoterRegistrationView = {
  name: Route.EDE_VOTER_REGISTRATION,
  component: VoterRegistration,
  options: {
    ...SplitFormBlueprint.options,
    title: 'Voter Registration',
  },
};
