import React from 'react';
import { View } from 'react-native';
import { AssetSize } from '@types';
import { Lottie } from './lottie';
import { Asset } from '../Asset';
import { theme as catchTheme } from '../../themes/catch';
import { useTheme } from '../../hooks/useTheme';
import { UIAccentColor } from '../../types';
import Cerise from './resources/cerise-loader.json';
import White from './resources/white-loader.json';
import CeriseSuccess from './resources/cerise-success.json';
import Fail from '@svg/close.svg';

const colors: Record<UIAccentColor | 'white', any> = {
  brand: Cerise,
  income: Cerise,
  taxes: Cerise,
  saved: Cerise,
  retirement: Cerise,
  coverage: Cerise,
  white: White, // doesn't fit accent colors...
};

const colorsDone: Record<UIAccentColor | 'white', any> = {
  brand: CeriseSuccess,
  income: CeriseSuccess,
  taxes: CeriseSuccess,
  saved: CeriseSuccess,
  retirement: CeriseSuccess,
  coverage: CeriseSuccess,
  white: CeriseSuccess,
};

interface SpinnerProps {
  color?: UIAccentColor | 'white';
  size?: AssetSize;
  large?: boolean;
  done?: boolean;
  error?: boolean;
}

export const Spinner: React.FC<SpinnerProps> = ({
  color = 'brand',
  size = 'sm',
  large,
  done,
  error,
}) => {
  const { theme } = useTheme();
  const _size = catchTheme.assets[large ? 'md' : size];

  return (
    <View
      testID="loader"
      style={[
        theme.centerSelf,
        theme.alignCenter,
        { width: _size, height: _size, justifyContent: 'center', overflow: 'hidden' },
      ]}
    >
      {error ? (
        <Asset
          svg={Fail}
          size="sm"
          containerSize="md"
          shape="circle"
          color="negative"
          bg="negativeLight"
        />
      ) : (
        <Lottie size={_size} animation={done ? colorsDone[color] : colors[color]} repeat={!done} />
      )}
    </View>
  );
};

export default Spinner;
