import React from 'react';
import { Copy, FontSize, FontWeight } from '@types';
import { Stack } from '@layouts';
import Text from './Text';
import HelpText from './HelpText';
import TestID from './TestID';
import { useCopy } from '@app/utils';

export interface FormLabelProps {
  testID?: string;
  label?: Copy;
  sublabel?: Copy;
  help?: Copy;
  optional: boolean;
}

export const FormLabel: React.FC<FormLabelProps> = ({
  testID,
  label,
  sublabel,
  help,
  optional,
}) => {
  const { c } = useCopy('catch');
  if (!label && !help) return null;

  return (
    <Stack horizontal flexItem={0} style={{ paddingBottom: 8 }}>
      <Stack>
        {!!optional && (
          <Text size={FontSize.fp} color="subtle" gutter="bottom">
            {c('optional')}
          </Text>
        )}
        {!!label && (
          <Text testID={`${testID}-label`} size={FontSize.label} weight={FontWeight.medium}>
            {label}
            <TestID>{testID}</TestID>
          </Text>
        )}
        {!!sublabel && (
          <Text size={FontSize.fp} color="subtle">
            {sublabel}
          </Text>
        )}
      </Stack>
      {!!help && <HelpText content={help} />}
    </Stack>
  );
};

export default FormLabel;
