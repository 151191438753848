import React from 'react';
import { Stack } from '@app/layouts';

import { Text } from './Text';
import { Copy, FontSize, TextAlign } from '@app/types';

export interface FinePrintProps {
  items: Array<Copy>;
  align?: TextAlign;
}

export const FinePrint = ({ items, align }: FinePrintProps) => {
  return (
    <Stack spacing="0b" topSpace>
      {items?.map((item, idx) => (
        <Text size={FontSize.sm} color="subtler" align={align} key={idx}>
          {item}
        </Text>
      ))}
    </Stack>
  );
};

export default FinePrint;
