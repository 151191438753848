import { gql } from '../generated';

export const GET_MONEY_ACTIVITIES = gql(`
  query GetMoneyActivities {
    activities {
      activityData {
        ... on BankTransferActivity {
          id
          amount
          dateInitialized
          direction
          status
        }
      }
    }
  }
`);

/**
 * Note: unlike some of the other definitions, this one
 * is page specific instead of model specific
 */
export const GET_MONEY_PAGE = gql(`
  query GetMoneyPage {
    bankAccount {
      ...BankAccount
    }
    goals {
      ...Goal
    }
    linkedAccounts {
      ...LinkedAccount
    }
  }
`);
