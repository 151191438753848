import { FieldConfig } from '@types';
import { workStateItems, capitalizeAll } from '@app/utils';
import { optionPresets, retirementAccountPresets } from '@f';
import { formatEIN } from '@app/utils/format_old/misc';
import { Occupation } from '@app/data';

export const fields: { [key: string]: FieldConfig } = {
  EMPLOYMENT_TYPE: {
    name: 'workType',
    type: 'option',
    required: true,
    // @todo standardize these!
    options: [
      {
        label: 'Independent (1099)',
        description: 'Taxes are not currently taken from any paychecks',
        value: 'WORK_TYPE_1099',
      },
      {
        label: 'Employee (W2)',
        description: 'Taxes are currently taken from every paycheck',
        value: 'WORK_TYPE_W2',
      },
      {
        label: 'Mix of both',
        description: 'Taxes are currently taken from some paychecks',
        value: 'WORK_TYPE_DIVERSIFIED',
      },
    ],
  },
  OCCUPATION: {
    name: 'occupation',
    type: 'dropdown',
    required: true,
    label: 'Select the closest option to your career',
    options: Object.values(Occupation).map((value) => ({
      label: capitalizeAll(value.split('_').join(' ')),
      value,
    })),
  },
  INCOME_1099: {
    name: 'estimated1099Income',
    type: 'amount',
    required: true,
    dependencies: ['workType'],
    display: ({ workType }) =>
      !workType || ['WORK_TYPE_1099', 'WORK_TYPE_DIVERSIFIED'].includes(workType),
    label: '1099 Income',
    amountType: 'integer',
  },
  INCOME_W2: {
    name: 'estimatedW2Income',
    type: 'amount',
    required: true,
    dependencies: ['workType'],
    display: ({ workType }) =>
      !workType || ['WORK_TYPE_W2', 'WORK_TYPE_DIVERSIFIED'].includes(workType),
    label: 'Annual W-2 income',
    amountType: 'integer',
  },

  OTHER_BENEFITS: {
    name: 'otherBenefits',
    type: 'multiSelect',
    required: false,
    options: [
      { label: 'Health Savings', value: 'HEALTH_EXPENSES' },
      { label: 'Time-Off', value: 'PTO' },
      { label: 'Family Leave', value: 'FAMILY_LEAVE' },
      { label: 'Emergency Savings', value: 'EMERGENCY_SAVINGS' },
    ],
  },
  GOAL_STEPPER: {
    name: 'paycheckPercentage',
    type: 'stepper',
    stepperType: 'percentage',
    label: 'per paycheck (recommended)',
    required: true,
    min: 1,
    // note: compute maximum value in implementation
  },
  WORK_STATE: {
    label: 'Work state',
    name: 'workState',
    type: 'dropdown',
    required: true,
    options: workStateItems,
  },
  FILING_STATUS: {
    label: 'Tax filing status',
    name: 'filingStatus',
    type: 'option',
    required: true,
    options: [
      { label: 'Single', value: 'SINGLE' },
      { label: 'Married filing jointly', value: 'MARRIED' },
      { label: 'Married filing separately', value: 'MARRIED_SEPARATELY' },
      { label: 'Head of household', value: 'HEAD' },
    ],
  },
  SPOUSE_INCOME: {
    label: "Spouse's income",
    name: 'spouseIncome',
    type: 'amount',
    required: true,
    dependencies: ['filingStatus'],
    amountType: 'integer',
  },
  DEPENDENTS: {
    name: 'numTaxDependents',
    type: 'stepper',
    required: false,
    stepperType: 'integer',
    label: 'Tax dependents',
    min: 0,
  },
  LEGAL_NAME: {
    name: 'name', // this acts as a prefix
    type: 'legalName',
    required: true,
    subfields: {
      givenName: true,
      middleName: true,
      familyName: true,
      nameSuffix: true,
    },
  },
  NICKNAME: {
    name: 'nickname',
    type: 'text',
    keyboard: 'firstName',
    placeholder: 'Name',
    required: true,
    label: 'Preferred name',
  },
  DOB: {
    name: 'dob',
    type: 'date',
    format: 'YYYY-MM-DD', // how to store the date
    required: false,
    label: 'Date of birth',
  },
  EMAIL: {
    name: 'email',
    type: 'email',
    required: true,
  },
  PHONE_NUMBER: {
    name: 'phoneNumber',
    type: 'phone',
    required: true,
  },
  SSN: {
    name: 'ssn',
    type: 'ssn',
    required: true,
    editable: true,
  },
  LEGAL_ADDRESS: {
    name: 'legalAddress',
    type: 'address',
    required: true,
  },
  HOME_ADDRESS: {
    name: 'homeAddress',
    type: 'address',
    required: false,
  },
  PAYCHECK_PERCENTAGE: {
    name: 'paycheckPercentage',
    type: 'stepper',
    stepperType: 'percentage',
    label: 'per paycheck',
    required: false,
    min: 1,
    dependencies: ['paycheckPercentage', 'grossIncome', 'totalPaycheckPercentage'],
    max: ({ totalPaycheckPercentage }) => 100 - totalPaycheckPercentage,
  },
  EXTERNAL_SAVINGS: {
    name: 'externalSavings',
    type: 'amount',
    label: 'Current savings',
    required: true,
    amountType: 'integer',
  },
  RISK_LEVEL: {
    label: 'Risk level',
    name: 'riskLevel',
    type: 'option',
    required: true,
    options: [
      { label: 'Withdraw my money', value: 'CONSERVATIVE' },
      { label: 'Deposit more money', value: 'AGGRESSIVE' },
      { label: 'Nothing', value: 'MODERATE' },
    ],
  },
  RISK_COMFORT: {
    label: 'Risk comfort',
    name: 'riskComfort',
    type: 'option',
    required: true,
    options: [
      { label: 'Very little risk', value: 'LESS_RISKY' },
      { label: 'Average risk', value: 'NEUTRAL' },
      { label: 'As much risk as possible', value: 'MORE_RISKY' },
    ],
  },
  RETIREMENT_PORTFOLIOS: {
    label: 'Portfolios',
    name: 'name',
    type: 'option',
    required: true,
    options: [
      {
        label: 'Bold',
        description: 'Higher risk, larger growth potential',
        value: 'AggressiveESG',
      },
      {
        label: 'Balanced',
        description: 'Medium risk, standard growth potential',
        value: 'ModerateESG',
      },
      {
        label: 'Modest',
        description: 'Lower risk, smaller growth potential',
        value: 'ConservativeESG',
      },
    ],
  },
  RETIREMENT_ACCOUNTS_OVER_21: {
    label: 'Accounts',
    name: 'accountType',
    type: 'option',
    required: true,
    options: retirementAccountPresets.OVER_21,
  },
  RETIREMENT_ACCOUNTS_UNDER_21: {
    label: 'Accounts',
    name: 'accountType',
    type: 'option',
    required: true,
    options: retirementAccountPresets.UNDER_21,
  },

  /**@todo probably want better way to consolidate these */
  FINANCIAL_SCREENING_PUBLICALLY_TRADED: {
    label: 'An officer, director, or 10% shareholder of a publicly traded company?',
    name: 'isControlPerson',
    type: 'radio',
    required: true,
    allowFalseValues: true,
    options: optionPresets.YES_NO_BOOLEAN,
  },
  FINANCIAL_SCREENING_FIRM_AFFILIATED: {
    label: 'Employed by a securities exchange, FINRA, or a registered securities brokerage firm?',
    name: 'isFirmAffiliated',
    type: 'radio',
    required: true,
    allowFalseValues: true,
    options: optionPresets.YES_NO_BOOLEAN,
  },
  FINANCIAL_SCREENING_POLITICAL: {
    label: 'A high-ranking military government, or political official of a foreign company?',
    name: 'isPoliticallyExposed',
    type: 'radio',
    required: true,
    allowFalseValues: true,
    options: optionPresets.YES_NO_BOOLEAN,
  },

  /** business info */
  DBA: {
    name: 'dba',
    type: 'text',
    placeholder: 'Name',
    required: true,
    label: 'Business name (DBA)',
    minLength: 3,
  },
  EIN: {
    name: 'ein',
    type: 'text',
    placeholder: 'EIN',
    label: 'Employer Identification Number (EIN)',
    format: formatEIN,
    keyboard: 'number',
    minLength: 10,
  },
};
