type EnvConfig = {
  [key: string]: string | undefined;
};

export type CatchEnv = 'prod' | 'staging' | 'dev';

export const variables = {
  NODE_ENV: process.env.NODE_ENV as 'production' | 'development' | 'test',
  CATCH_ENV: process.env.REACT_APP_CATCH_ENV as CatchEnv,
  AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN as string,
  AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
  AUTH0_AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE as string,
  WEBSITE_URL: process.env.REACT_APP_WEBSITE_URL as string,
  GRAPHQL_URL: process.env.REACT_APP_GRAPHQL_URL as string,
  FINTECH_URL: process.env.REACT_APP_FINTECH_URL as string,
  PUBLIC_URL: process.env.REACT_APP_PUBLIC_URL as string,
  GRAPHCMS_URL: process.env.REACT_APP_GRAPHCMS_URL as string,
  LOGROCKET_APP_ID: process.env.REACT_APP_LOGROCKET_APP_ID as string,
  DATADOG_RUM_CLIENT_TOKEN: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN as string,
  VERSION_NUMBER: process.env.REACT_APP_VERSION_NUMBER as string,
  BUILD_NUMBER: process.env.REACT_APP_BUILD_NUMBER as string,
};

export const optional = {
  HYGRAPH_TOKEN: process.env.REACT_APP_HYGRAPH_TOKEN as string | undefined,
};

export const env = {
  ...variables,
  ...optional,
  IS_TEST: variables.NODE_ENV === 'test',
  IS_LOCAL: variables.NODE_ENV === 'development',
  IS_DEV: variables.CATCH_ENV === 'dev',
  IS_STAGE: variables.CATCH_ENV === 'staging',
  IS_PROD: variables.CATCH_ENV === 'prod',
};

// throws warning if any raw variables are undefined
function checkEnv(config: EnvConfig): void {
  Object.entries(config).forEach(([key, value]) => {
    if (!value) {
      console.warn(`Warning: Environment variable ${key} is not set.`);
    }
  });
}

// run the environment check, but only on the base mapping
checkEnv(variables);
