import { create } from 'zustand';
import { Copy, PolicyProps } from '@types';
import { currentPage } from './history';
import { GoalFragment } from '@app/data';
import { TaxRecommendations } from '@app/features/goals/hooks/useTaxRecommendations';

export type SheetName =
  | 'LOADER'
  | 'COVERAGE_MENU'
  | 'COVERAGE_DETAIL_MENU'
  | 'COVERAGE_MANAGED_OUTSIDE'
  | 'HELP_TEXT'
  | 'NEXT_PLAN_SELECTION'
  | 'HEALTH_SCREENED_OUT'
  | 'REFETCH_ELIGIBILITY'
  | 'PAYMENT_REDIRECT'
  | 'CANCEL_POLICY'
  | 'ADD_TO_WALLET'
  | 'MISSING_HEALTH_SSN'
  | 'MISSING_IMMIGRATION_FIELDS'
  | 'SILVER_PLAN_ELIGIBLE'
  | 'APPLIED_APTC'
  | 'TRANSFER_OPTIONS'
  | 'VOTER_INFO'
  | 'WITHHOLDING_EDIT'
  | 'TAX_BREAKDOWN_GUIDE';

export const sheets: Record<SheetName, SheetName> = {
  COVERAGE_MENU: 'COVERAGE_MENU',
  COVERAGE_DETAIL_MENU: 'COVERAGE_DETAIL_MENU',
  COVERAGE_MANAGED_OUTSIDE: 'COVERAGE_MANAGED_OUTSIDE',
  HELP_TEXT: 'HELP_TEXT',
  LOADER: 'LOADER',
  NEXT_PLAN_SELECTION: 'NEXT_PLAN_SELECTION',
  HEALTH_SCREENED_OUT: 'HEALTH_SCREENED_OUT',
  REFETCH_ELIGIBILITY: 'REFETCH_ELIGIBILITY',
  PAYMENT_REDIRECT: 'PAYMENT_REDIRECT',
  CANCEL_POLICY: 'CANCEL_POLICY',
  ADD_TO_WALLET: 'ADD_TO_WALLET',
  MISSING_HEALTH_SSN: 'MISSING_HEALTH_SSN',
  MISSING_IMMIGRATION_FIELDS: 'MISSING_IMMIGRATION_FIELDS',
  SILVER_PLAN_ELIGIBLE: 'SILVER_PLAN_ELIGIBLE',
  APPLIED_APTC: 'APPLIED_APTC',
  TRANSFER_OPTIONS: 'TRANSFER_OPTIONS',
  VOTER_INFO: 'VOTER_INFO',
  WITHHOLDING_EDIT: 'WITHHOLDING_EDIT',
  TAX_BREAKDOWN_GUIDE: 'TAX_BREAKDOWN_GUIDE',
};

interface SheetProps {
  navBg?: string;
  closeSheet: () => void;
}

/**
 * Prop types for each screen;  these are the parasms to pass when opening
 */
interface CoverageDetailMenuProps {}

interface PauseGoalProps {
  goal: any;
}

interface HelpTextProps {
  title: Copy;
  subtitle: Copy;
}

interface HealthPlanBrochureProps {
  scoredHealthPlan: any;
  enrollmentType: any;
}

interface LoaderProps {
  title?: any;
}

interface NextPlanSelectionProps {}

interface CoverageManagedOutsideProps {
  managedBy: 'HEALTHCARE_GOV' | 'HEALTHSHERPA';
  reason: 'DENTAL' | 'HEALTHCARE_GOV' | 'HEALTHSHERPA';
}

interface HealthScreenedOutProps {
  handleFailedScreening: () => void;
  pathwayType: any;
  enrollId: string;
  failedQuestion: string;
  phase: string;
  coverageYear: number;
}

interface RefetchEligibilityProps {
  applicationID: string;
}

interface PaymentRedirectProps {
  policy: PolicyProps;
  enrollment: {
    id: string;
    year: number;
    enrollmentType: string;
    healthApplication: {
      id: string;
    };
  };
}

interface CancelPolicyProps extends SheetProps {
  enrollmentID: string;
  enrollmentType: 'HEALTH' | 'DENTAL';
  actionEffectiveDate: Date;
}

interface AddToWalletProps extends SheetProps {}

interface TransferOptionsProps extends SheetProps {
  goal?: GoalFragment;
}

interface VoterInfoProps extends SheetProps {}

interface WithholdingEditProps extends SheetProps {
  closeSheet: () => void;
  goal: GoalFragment;
}

interface TaxBreakdownGuideProps extends SheetProps {
  calculation: TaxRecommendations;
}

export type ContentComponentProps =
  | CoverageDetailMenuProps
  | PauseGoalProps
  | HelpTextProps
  | HealthPlanBrochureProps
  | LoaderProps
  | CoverageManagedOutsideProps
  | RefetchEligibilityProps
  | PaymentRedirectProps
  | CancelPolicyProps
  | AddToWalletProps
  | TransferOptionsProps
  | VoterInfoProps
  | WithholdingEditProps;

export interface SheetConfig {
  name: SheetName;
  data?: ContentComponentProps;
}

export interface SheetComponentProps {
  COVERAGE_MENU: SheetProps;
  COVERAGE_DETAIL_MENU: CoverageDetailMenuProps;
  COVERAGE_MANAGED_OUTSIDE: CoverageManagedOutsideProps;
  PAUSE_GOAL: PauseGoalProps;
  TAX_PAYMENT_GUIDE: SheetProps;
  HELP_TEXT: HelpTextProps;
  HEALTH_PLAN_BROCHURE: HealthPlanBrochureProps;
  LOADER: LoaderProps;
  NEXT_PLAN_SELECTION: NextPlanSelectionProps;
  HEALTH_SCREENED_OUT: HealthScreenedOutProps;
  REFETCH_ELIGIBILITY: RefetchEligibilityProps;
  PAYMENT_REDIRECT: PaymentRedirectProps;
  CANCEL_POLICY: CancelPolicyProps;
  ADD_TO_WALLET: AddToWalletProps;
  TRANSFER_OPTIONS: TransferOptionsProps;
  VOTER_INFO: VoterInfoProps;
  WITHHOLDING_EDIT: WithholdingEditProps;
  TAX_BREAKDOWN_GUIDE: TaxBreakdownGuideProps;
}

interface ActiveSheet {
  // unique id: combination of name and referrer
  id: string; // e.g. TRANSFER_SHEET-aks8csjk43

  name: SheetName;
  referrer: string; // page that opened this
  data: { [key: string]: any };
}

type SheetStore = {
  active: Array<ActiveSheet>;
  open: (sheet: SheetName, data?: ActiveSheet['data']) => void;
  close: (sheet: SheetName) => void;
  replace: (sheet: SheetName, data?: ActiveSheet['data']) => void;
  closeById: (id: string) => void;
  closeAll: () => void;
};

export const useSheet = create<SheetStore>((set, get) => ({
  active: [],

  open: (name: SheetName, data = {}) => {
    const referrer = currentPage();
    const id = `${name}-${referrer}`;

    // ensures that the sheet isn't already open
    const index = get().active.findIndex((s) => s.id === id);
    if (index < 0) {
      const updated = [...get().active, { id, name, referrer, data }];
      set({ active: updated });
    }
  },
  closeById: (id: string) => {
    const current = [...get().active];
    const index = current.findIndex((s) => s.id === id);
    if (index > -1) current.splice(index, 1);
    set({ active: current });
  },
  close: () => {
    // @todo
  },
  replace: (name: SheetName, data = {}) => {
    const [, ...current] = [...get().active];
    const referrer = currentPage();
    const id = `${name}-${referrer}`;

    // ensures that the sheet isn't already open
    const index = current.findIndex((s) => s.id === id);
    if (index < 0) {
      const updated = [...current, { id, name, referrer, data }];
      set({ active: updated });
    }
  },
  closeAll: () => {
    set({ active: [] });
  },
}));
