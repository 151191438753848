import React from 'react';
import styled from 'styled-components';
import { ApplicantAgeWarning } from '@app/partial';
import { FieldAdditionID } from '@types';
import { Text, ComplexRow, Banner } from '@uikit';
import { useCopy } from '@app/utils';
import { Layout } from '@app/layouts';

const StyledAddition = styled.div`
  padding-top: 8px;
`;

const Warning: React.FC<{ copy: { id: string; data?: object; prefix?: string } }> = ({ copy }) => {
  const { c } = useCopy(copy.prefix || 'catch');

  return (
    <Text size="fp" color="credit" weight="bold" gutter="top">
      {c(copy.id, copy.data || {})}
    </Text>
  );
};

const BasicText: React.FC<{ copy: { id: string; data?: object; prefix?: string } }> = ({
  copy,
}) => {
  const { c } = useCopy(copy.prefix || 'catch');

  return (
    <Text size="fp" style={{ paddingTop: 12 }}>
      {c(copy.id, copy.data || {})}
    </Text>
  );
};

const BasicBanner = (props) => {
  return <Banner {...props} style={{ marginTop: 16 }} />;
};

const FinancialAssistanceWarning = () => {
  const { c } = useCopy('catch.ede.Screening');

  return (
    <Layout margins topSpace>
      <ComplexRow bg="coverageLight" label={c('banner.title')} sublabel={c('banner.subtitle')} />
    </Layout>
  );
};

const BasicComponent = (props) => {
  const Component = props.Component;
  return Component || null;
};

const components: Record<FieldAdditionID, React.FC> = {
  APPLICANT_AGE_WARNING: ApplicantAgeWarning,
  FINANCIAL_ASSISTANCE_WARNING: FinancialAssistanceWarning,
  WARNING: Warning,
  TEXT: BasicText,
  BANNER: BasicBanner,
  CUSTOM: BasicComponent,
};

const FieldAddition = ({ component, props }) => {
  if (!component) return null;
  const Component = components[component];

  return (
    <StyledAddition>
      <Component {...props} />
    </StyledAddition>
  );
};

export default FieldAddition;
