import React, { useMemo } from 'react';
import styled from 'styled-components';
import { FontSize, FontWeight, TextAlign } from '@types';
import { Color } from '@app/styles';
import { useDebug } from '@hooks/useDebug';
import { Text } from '../Text';
import { Icon, IconType } from '../Icon';
import { schemes } from './schemes';
import { useLayoutContext } from '@app/layouts';
import Spinner from '../Spinner';

const sizes = {
  mini: {
    height: 28,
    padding: 12,
    font: FontSize.xs,
    icon: 'xs',
  },
  small: {
    height: 42,
    padding: 16,
    font: FontSize.sm,
    icon: 'sm',
  },
  regular: {
    height: 44,
    padding: 24,
    font: FontSize.p,
    icon: 'sm',
  },
};

export type ButtonScheme = keyof typeof schemes;
export type ButtonSize = keyof typeof sizes;

interface SharedButtonProps {
  testID: string;
  loading?: boolean;
  type?: 'button' | 'submit';
  alt?: boolean;
  scheme?: ButtonScheme;
  inherit?: boolean;
  size?: ButtonSize;
  full?: boolean;
  wide?: boolean;
  icon?: IconType;
  disabled?: boolean;
  onPress?: () => void;
  onHover?: () => void;
  label?: string;
  children?: React.ReactNode;
}

interface IconButtonProps extends SharedButtonProps {
  icon: IconType;
  label: string;
}

interface TextButtonProps extends SharedButtonProps {
  children: React.ReactNode;
}

export type ButtonProps = IconButtonProps | TextButtonProps;

const StyledButton = styled.button<{
  $bg: Color;
  $hover: Color;
  $gradient: Array<string>;
  $size: ButtonSize;
  $full: boolean;
  $wide: boolean;
  $onlyIcon: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  border-radius: 5000px;
  height: ${(p) => sizes[p.$size].height}px;
  line-height: ${(p) => sizes[p.$size].height}px;
  transition: all 300ms ease-out;
  box-shadow: ${({ theme, $bg }) => theme.colors[$bg]}0F 0px 3px 21px;
  padding: 0px ${(p) => sizes[p.$size].padding}px;
  ${(p) => (p.$full || p.$wide ? `width: 100%;` : 'width: fit-content;')}
  ${(p) => p.$wide && `max-width: ${p.theme.containers.dialog}px;`}

  ${(p) =>
    p.$onlyIcon &&
    `
    width: ${sizes[p.$size].height}px;
    max-width: ${sizes[p.$size].height}px;
    padding: 0px;
  `}

  ${({ theme, $bg, $gradient }) =>
    $gradient
      ? `
    background: linear-gradient(to bottom, ${$gradient[0]}, ${$gradient[1]});
  `
      : `
    background-color: ${theme.colors[$bg]};
  `}
  
  &:focus {
    transition: transform 50ms ease-out;
    box-shadow:
      0 0 0 1px ${({ theme }) => theme.colors.bg},
      0 0 0 3px ${({ theme }) => theme.colors.system};
  }

  &:hover:not([disabled]) {
    transition: transform 50ms ease-out;
    cursor: pointer;
    opacity: 0.9;
    background-color: ${({ theme, $hover }) => theme.colors[$hover]};
  }

  &[disabled] {
    opacity: 0.5;
  }

  > * {
    &:not(:last-child):not(.spinner) {
      margin-right: ${(p) => sizes[p.$size].padding / 2}px;
    }

    ${(p) =>
      p.$loading &&
      `
      &:not(.spinner) {
        opacity: 0;
      }
    `}
  }
`;

const StyledLoader = styled.div`
  position: absolute;
`;

export function Button({
  testID,
  loading,
  type,
  size = 'regular',
  inherit,
  scheme = 'primary',
  alt,
  onPress,
  onHover,
  icon,
  label,
  full,
  wide,
  disabled,
  children,
}: ButtonProps) {
  const { showTestIds } = useDebug();
  const { accentColor } = useLayoutContext();

  const { gradient, bg, hover, text } = useMemo(() => {
    const selector = alt ? 'light' : 'regular';
    const applyScheme = inherit ? accentColor : scheme;

    if (!schemes[applyScheme]) {
      console.log('MISSING', applyScheme);
    }

    return schemes[applyScheme]?.[selector] || schemes.primary[selector];
  }, [scheme, alt, accentColor, inherit]);

  const handlePress = () => {
    if (onPress && !disabled) {
      onPress();
    }
  };

  const handleHover = () => {
    if (onHover && !disabled) {
      onHover();
    }
  };

  const handleKey = ({ key }) => {
    if (key === 'Enter') {
      handlePress();
    }
  };

  return (
    <StyledButton
      data-testid={testID}
      name={label}
      type={type}
      $bg={bg}
      $gradient={gradient}
      $hover={hover}
      $size={size}
      $full={!!full}
      $wide={!!wide}
      $onlyIcon={!!icon && !children}
      $loading={!!loading}
      disabled={disabled}
      onClick={handlePress}
      onKeyPress={handleKey}
      onMouseOver={handleHover}
    >
      {loading && (
        <StyledLoader className="spinner">
          <Spinner color="white" size={sizes[size].icon} />
        </StyledLoader>
      )}

      {icon && <Icon contained={false} interactive={false} type={icon} color={text} size={size} />}

      {children && (
        <Text
          inline
          align={TextAlign.center}
          weight={FontWeight.medium}
          color={text}
          size={sizes[size].font}
        >
          {showTestIds ? testID : children}
        </Text>
      )}
    </StyledButton>
  );
}
