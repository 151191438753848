import { Navigate } from 'react-router';
import { stacks, Stack, StackComponent, useCurrentRoute, paths } from '@navigate';
import { OnboardingConsentView } from './routes/OnboardingConsentView';

const config = {
  stackName: stacks.ONBOARDING_STACK,
  options: {},
  screens: [OnboardingConsentView],
};

const OnboardingStack: StackComponent = () => {
  // note: if we don't actually match a pageview, we should redirect to the first screen
  const route = useCurrentRoute();
  if (!route) return <Navigate to={paths.ONBOARDING_CONSENT} />;

  return <Stack stackName={config.stackName} screens={config.screens} options={config.options} />;
};

OnboardingStack.config = config;
export default OnboardingStack;
