import React, { useMemo } from 'react';
import { close } from '@navigate';
import { Button } from '@uikit';
import { Toolbar, BasicLayout } from '@layouts';
import { useForm, Fields, formatPayload } from '@app/forms';
import { fields } from '@app/config';
import { WorkType, FilingStatus, FieldsConfig, Route } from '@types';
import { useQuery, useMutation, UserQuery, UpdateUser } from '@data';

type EditFieldType =
  | 'NICKNAME'
  | 'LEGAL_NAME'
  | 'DOB'
  | 'LEGAL_ADDRESS'
  | 'OCCUPATION'
  | 'EMPLOYMENT_TYPE'
  | 'WORK_STATE'
  | 'FILING_STATUS'
  | 'ESTIMATED_INCOME'
  | 'SPOUSE_INCOME'
  | 'BUSINESS';

interface EditUserDetailsProps {
  field: EditFieldType;
}

interface FormValues {
  nickname?: string;
  givenName?: string;
  familyName?: string;
  dob?: string;
  legalAddress?: {
    street1?: string;
    street2?: string;
    city?: string;
    state?: string;
    zip?: string;
  };
  workType?: WorkType;
  occupation?: string;
  estimated1099Income?: number;
  estimatedW2Income?: number;
  workState?: string;
  filingStatus?: FilingStatus;
  spouseIncome?: number;
}

const configs: Record<EditFieldType, FieldsConfig> = {
  NICKNAME: [fields.NICKNAME],
  LEGAL_NAME: [fields.LEGAL_NAME],
  DOB: [fields.DOB],
  LEGAL_ADDRESS: [fields.LEGAL_ADDRESS],
  OCCUPATION: [fields.OCCUPATION],
  EMPLOYMENT_TYPE: [fields.EMPLOYMENT_TYPE],
  WORK_STATE: [fields.WORK_STATE],
  FILING_STATUS: [fields.FILING_STATUS],
  ESTIMATED_INCOME: [fields.INCOME_1099, fields.INCOME_W2],
  SPOUSE_INCOME: [fields.SPOUSE_INCOME],
  BUSINESS: [fields.DBA, fields.EIN],
};

const EditUserDetails: React.FC<EditUserDetailsProps> = ({ field }: EditUserDetailsProps) => {
  const { loading, data } = useQuery(UserQuery, {
    fetchPolicy: 'cache-first',
  });

  const [update, { loading: submitting }] = useMutation(UpdateUser, {
    onCompleted: () => close(),
  });

  const fields = useMemo(() => {
    return field && field in configs ? configs[field] : [];
  }, [field]);

  const initialValues = {
    nickname: data?.viewer?.user.nickname,
    givenName: data?.viewer?.user.givenName,
    familyName: data?.viewer?.user.familyName,
    dob: data?.viewer?.user.dob,
    legalAddress: data?.viewer?.user.legalAddress,
    workType: data?.viewer?.user.workType,
    occupation: data?.viewer?.user.occupation,
    estimated1099Income: data?.viewer?.user.income?.estimated1099Income,
    estimatedW2Income: data?.viewer?.user.income?.estimatedW2Income,
    spouseIncome: data?.viewer?.user.income?.spouseIncome,
    workState: data?.viewer?.user.workState,
    filingStatus: data?.viewer?.user.filingStatus,
    dba: data?.viewer?.user.dba,
    ein: data?.viewer?.user.ein,
  };

  const form = useForm<FormValues>({
    loading,
    disabled: submitting,
    initialValues: initialValues,
    fields,
    onSubmit: (values) => {
      const payload = formatPayload(values, fields);
      console.log({ values, fields, payload });
      update({ variables: { input: payload } });
    },
  });

  return (
    <BasicLayout
      loading={loading || submitting}
      title="Update your info"
      toolbar={
        <Toolbar>
          <Button alt testID="cancel" onPress={close} disabled={form.disableSubmit}>
            Cancel
          </Button>
          <Button
            testID="update"
            onPress={form.submitForm}
            disabled={form.disableSubmit}
            loading={submitting}
          >
            Update
          </Button>
        </Toolbar>
      }
    >
      <Fields form={form} fields={fields} />
    </BasicLayout>
  );
};

export const EditUserDetailsView = {
  name: Route.EDIT_USER_DETAILS,
  component: EditUserDetails,
  options: {
    bg: 'sheet',
    layout: 'sheet',
  },
};
