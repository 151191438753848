import React from 'react';
import { useIntl } from 'react-intl';
import { Text } from '@uikit/components/Text';
import { Link } from '@uikit/components/Link';
import { currentYear, sixtyDaysAgo, sixtyDaysFromNow } from '../time';
import { useUniversalCopy } from './useUniversalCopy';

export const DEFAULT_PREFIX = 'catch';

const UNIVERSAL_COPY = {
  universalTransferTime: '2-3 business days',
  universalKycTime: '1-2 business days',
  linkRewardTime: '7-10 days',
};

// @ts-ignore
export function useCopy(prefix) {
  const { formatMessage, formatNumber, messages, locale } = useIntl();
  const {
    marketplaceName,
    theMarketplace,
    theShortMarketplace,
    marketplaceSite,
    provider,
    callCenter,
    fullChipName,
    chipName,
    medicaidName,
    assistanceURL,
    agentLookupURL,
    isGA,
  } = useUniversalCopy();

  function format(id, values = {}): string {
    const msgID = `${prefix || DEFAULT_PREFIX}.${id}`;

    if (!messages[msgID]) {
      return '';
    }

    const message = formatMessage(
      {
        id: msgID,
      },
      {
        year: currentYear,
        break: '<br />',
        newline: '\n',

        // weights
        light: (str) => <Text weight="regular">{str}</Text>,
        medium: (str) => <Text weight="medium">{str}</Text>,
        bold: (str) => <Text weight="bold">{str}</Text>,

        // colors
        subtle: (str) => <Text color="subtle">{str}</Text>,
        coverage: (str) => <Text color="coverage">{str}</Text>,

        // special
        inlineLink: (str) => {
          const [content, href] = str[0].split('::');
          return (
            <Link testID={href} href={href} inline>
              {content}
            </Link>
          );
        },
        marketplaceName,
        theMarketplace,
        provider,
        callCenter,
        chipName,
        fullChipName,
        medicaidName,
        assistanceURL,
        agentLookupURL,
        theShortMarketplace,
        marketplaceSite,
        isGA,
        sixtyDaysAgo: sixtyDaysAgo(),
        sixtyDaysFromNow: sixtyDaysFromNow(),
        ...UNIVERSAL_COPY,
        ...values,
      },
    );

    return message || '';
  }

  function formatCurrency(value, { whole } = {}) {
    return formatNumber(value, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: whole ? 0 : 2,
      maximumFractionDigits: whole ? 0 : 2,
    });
  }

  function formatPercent(value, { whole, minimumFractionDigits, maximumFractionDigits } = {}) {
    return formatNumber(value, {
      style: 'percent',
      minimumFractionDigits: minimumFractionDigits || (whole ? 0 : undefined),
      maximumFractionDigits: maximumFractionDigits || (whole ? 0 : undefined),
    });
  }

  /** @todo add some TS to these, see https://catchco.slack.com/archives/CFENY3RFB/p1674142800868799?thread_ts=1674127940.081879&cid=CFENY3RFB */
  return {
    locale,
    formatMessage,
    c: format,
    $: formatCurrency,
    p: formatPercent,
    n: formatNumber,
  };
}
