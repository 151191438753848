import React from 'react';
import { View } from 'react-native';
import {
  useMutation,
  mutations,
  RemoveExplorerApplicantResponse,
  RemoveExplorerApplicantVars,
  updates,
} from '@app/data';
import { Card, Stack } from '@app/layouts';
import { Asset, SimpleRow } from '@uikit';
import { useCopy } from '@app/utils';
import Close from '@svg/close.svg';
import { Color } from '@app/styles';

const ExplorerApplicantsCard = ({ children, values, remove }) => {
  const { c: dependentsList } = useCopy('catch.health.DependentsList');
  const { c: basics } = useCopy('catch.basics');

  const relationCopy = {
    SELF: dependentsList('self'),
    SPOUSE: dependentsList('spouse'),
    CHILD: dependentsList('myChild'),
  };

  const cancelCopy = basics('cancel');

  const [removeApplicant] = useMutation<
    RemoveExplorerApplicantResponse,
    RemoveExplorerApplicantVars
  >(mutations.REMOVE_EXPLORER_APPLICANT);

  const handleRemove = () => {
    if (values?.id) {
      removeApplicant({
        variables: { applicantID: values.id },
        update: (cache) => updates.REMOVE_EXPLORER_APPLICANT(cache, { applicantID: values.id }),
      });
    }

    remove();
  };

  return (
    <View style={{ marginBottom: 16 }}>
      <Card border bg={Color.bg}>
        {values?.relation !== 'SELF' && (
          <Asset
            onPress={handleRemove}
            svg={Close}
            size="glyph"
            containerSize="sm"
            shape="circle"
            bg="skeleton"
            absolute
            ariaLabel={cancelCopy}
          />
        )}

        <SimpleRow
          testID="person"
          label={relationCopy[values?.relation]}
          asset={{ render: 'person' }}
        />

        <Stack spacing="2">{children}</Stack>
      </Card>
    </View>
  );
};

export default ExplorerApplicantsCard;
