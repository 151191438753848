import { gql } from '../generated';

gql(`
  fragment BaseExplorer on HealthExplorerData {
    id
    pathway
    state
    providerPlanID
    canCreateEnrollment
  }
`);

export const IMPORT_PUBLIC_EXPLORER = gql(`
  mutation ImportPublicExplorer($hid: ID!) {
    importExplorerFromPublic(id: $hid) {
      ...BaseExplorer
    }
  }
`);
