import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { navigate, checkActiveTab, TABS } from '@navigate';
import { Button, Icon, useResponsive, useTheme } from '@uikit';
import { useCopy, useLocale } from '@app/utils/language';
import { Segment, SegmentEvent, openIntercom } from '@app/utils';
import Env from '@app/utils/env';
import { NavBar, Stack } from '@layouts';
import { NavMode } from '@types';
import { AvatarMenu, NavMenu, DevMenu, NavLink } from './components';
import { NEW_TAB_NAMES, TAB_MONEY } from '@app/navigate/config/tabs';
import { useHealthStore } from '@hooks/useHealthStore';
import { Route } from '@types';
import { toggleLanguage } from '@app/utils/language/locale';
import { useAuth } from '@auth';
import { useFlags } from '@app/hooks/useFlags';

interface PageNavProps {
  title: string;
  navHex: string;
  isTransparent: boolean;
  navMode?: NavMode;
  canGoBack: boolean;
}

/**
 * Renders the page nav
 * @todo make the nav and themeColor work from the native arguments
 */
const PageNav = ({ navHex, navMode }: PageNavProps) => {
  const { c } = useCopy('catch.basics');
  const { isOnboarded } = useAuth();

  const { colorScheme } = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const { nextLanguage } = useLocale();
  const { pathname } = useLocation();
  const { shortRange } = useResponsive();
  const isMobile = shortRange !== 'l';
  const isHealthUrl = pathname?.includes('/health');
  const isHealthAppUrl = pathname?.includes('/health/application');
  const { context } = useHealthStore();
  const { loading, hasAccessToFintech } = useFlags();

  const onSaveAndExit = useCallback(() => {
    Segment.simpleTrack(SegmentEvent.EDE_SAVE_AND_EXIT);
    navigate(Route.COVERAGE);
  }, [Route.COVERAGE]);

  const showMiddleTabs = !isMobile && isOnboarded && !loading;
  let tabs = TABS;

  if (!hasAccessToFintech) {
    tabs = tabs.filter((item) => item !== TAB_MONEY);
  }

  const middleTabs = tabs.map((tab) => (
    <NavLink
      hide={!!navMode && navMode !== 'root'}
      key={tab}
      action={{ type: 'ROUTE', route: tab }}
      active={checkActiveTab(tab, pathname)}
    >
      {NEW_TAB_NAMES[tab]}
    </NavLink>
  ));

  const onLogoAction = {
    type: 'ROUTE',
    route: Route.HOME,
  };

  return (
    <>
      <NavBar
        onLogo={onLogoAction}
        middle={showMiddleTabs ? middleTabs : null}
        right={
          <Stack horizontal spacing="1">
            <Stack horizontal spacing="0b">
              {/* Georgia Access logo */}
              {!!isHealthUrl && context === 'GA' && (
                <img
                  src={`https://s.catch.co/img/ga/ga${colorScheme === 'dark' ? '_invert' : ''}.png`}
                  style={styles.gaLogo}
                  alt="Georgia Access"
                />
              )}

              {!!isHealthAppUrl && !isMobile && (
                <Button testID="save-and-exit" onPress={onSaveAndExit} size="mini" alt>
                  {c('saveAndExitButton')}
                </Button>
              )}

              {/* @ts-ignore */}
              {Env.isDevLike && <DevMenu />}

              {/* English/Spanish switch for health application */}
              {!!isHealthUrl && (
                <Icon type="Globe" label={nextLanguage()} onPress={toggleLanguage} />
              )}

              <Icon type="Chat" label="Help" onPress={() => openIntercom()} />
            </Stack>

            {!isMobile && <AvatarMenu />}
            {!!isMobile && <Icon type="Menu" label="Menu" onPress={() => setIsOpen(!isOpen)} />}
            {/* @ts-ignore */}
          </Stack>
        }
      />
      {isOpen && <NavMenu background={navHex} onClose={() => setIsOpen(false)} />}
    </>
  );
};

const styles = {
  gaLogo: { height: 48, opacity: 0.5 },
};

export default PageNav;
