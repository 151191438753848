import { BasicFormBlueprint } from '@blueprints';
import {
  useQuery,
  useMutation,
  GET_CURRENT_USER,
  UpdateUser,
  SIGN_AGREEMENTS,
  AgreementType,
} from '@data';
import { ScreenDefinition } from '@navigate';
import { Button } from '@uikit';
import { safeFormatDate } from '@util';
import { Route } from '@types';
import { useMemo } from 'react';
import { formatAgreement, useAgreement } from '@app/hooks';

const ExplorerConsent = ({ handleNext }) => {
  const { loading, data } = useQuery(GET_CURRENT_USER);
  const [updateUser] = useMutation(UpdateUser);

  const [signAgreements] = useMutation(SIGN_AGREEMENTS, {
    onCompleted: () => handleNext(),
  });

  const consent = useAgreement(AgreementType.BrokerConsent);

  const initialValues = useMemo(() => {
    return {
      givenName: data?.me.user?.givenName,
      familyName: data?.me.user?.familyName,
    };
  }, [data]);

  const handleSubmit = (values) => {
    updateUser({
      variables: {
        input: {
          givenName: values.givenName,
          familyName: values.familyName,
        },
      },
    });

    const input = [
      {
        agreementType: consent.agreement?.agreementType as AgreementType,
        version: consent.agreement?.version as string,
      },
    ];

    signAgreements({ variables: { input } });
  };

  const consentFields = [
    {
      name: 'name',
      type: 'legalName',
      label: 'Name',
      required: true,
      subfields: {
        givenName: true,
        middleName: false,
        familyName: true,
        nameSuffix: false,
      },
    },
    {
      type: 'fieldset',
      name: 'agreementFieldset',
      label: 'Broker Consent Agreement',
      fields: [
        {
          type: 'textarea',
          name: 'brokerConsent',
          dependencies: ['name'],
          readOnly: true,
          rows: '6',
          value: ({ name }) => {
            const fullName = name.givenName
              ? `${name?.givenName}${name.familyName ? ` ${name.familyName}` : ''}`
              : '';

            return formatAgreement(consent?.agreement, {
              legalName: fullName ? `, ${fullName},` : '',
              date: safeFormatDate(new Date(), 'MMMM d, yyyy'),
            });
          },
        },
        {
          type: 'checkbox',
          name: 'brokerConsent',
          required: true,
          plain: true,
          label: 'By checking this box, I am providing my digital signature',
        },
      ],
    },
  ];

  return (
    <BasicFormBlueprint
      title="Opt-in to working with Catch"
      subtitle="Sign your broker consent"
      actions={({ handleSubmit, disabled }) => [
        <Button key="submit" testID="sign-consent" onPress={handleSubmit} disabled={disabled}>
          Submit
        </Button>,
      ]}
      loading={loading || consent.loading}
      formConfig={{
        initialValues,
        fields: consentFields,
        onSubmit: handleSubmit,
      }}
    />
  );
};

export const ExplorerConsentView: ScreenDefinition = {
  name: Route.EXPLORER_CONSENT,
  component: ExplorerConsent,
  guest: true,
  options: {
    title: 'Broker Consent',
  },
};
