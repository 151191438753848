import React from 'react';
import styled from 'styled-components';

interface FieldsetProps {
  legend: string;
  sublabel?: string;
  optional?: boolean;
  children: React.ReactNode;
}

const StyledFieldset = styled.fieldset`
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 12px;
  padding: 16px 4px 0 16px;
`;

const StyledLegend = styled.legend`
  font-weight: 500;
`;

const StyledSublabel = styled.span`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.textSecondary};
`;

const Fieldset: React.FC<FieldsetProps> = ({ legend, sublabel, optional, children }) => {
  return (
    <StyledFieldset>
      <StyledLegend>
        {legend} {optional && <StyledSublabel>(Optional)</StyledSublabel>}
      </StyledLegend>
      {sublabel && <StyledSublabel>{sublabel}</StyledSublabel>}
      {children}
    </StyledFieldset>
  );
};

export default Fieldset;
